import React from "react"
import { graphql } from "gatsby"
import { Layout, GridContainer } from "../components/common"
import styled from "styled-components"

import { replaceLinksWithNewTabLinks } from "../utils/common/content"
import media from "../styles/media"
import SEO from "../components/common/SEO"

const Container = styled(GridContainer)`
  padding-bottom: 100px;

  ${media.tablet`
    margin-bottom: 86px;
  `}
`
const LeftPageContent = styled.div`
  grid-column: 1 / span 3;
  margin-top: 0;

  ${media.tablet`
    grid-column: 1 / span 6;
  `}
`
const BodyContent = styled.div`
  display: grid;
  grid-column: 1 / -1;
  line-height: 30px;
  font-size: 20px;
  padding-top: 50px;

  p {
    margin-top: 0;
  }

  img {
    width: 100%;
  }

  ${media.tablet`
    grid-column: 7 / span 6;
    padding-top: 4px;
    margin-right: 20px;
  `}
`
const Title = styled.p`
  margin: 0 auto;
  font-family: StanleyRegular, serif;
  letter-spacing: 0;
  text-align: left;
  font-size: 30px;
  line-height: 32px;

  ${media.tablet`
  font-size: 45px;
  font-weight: lighter;
  line-height: 52px;
  `}
`
const Date = styled.p`
  margin: 0 auto;
  color: ${props => props.theme.lightGrey};
  letter-spacing: 1.33px;
  line-height: 30px;
  text-transform: uppercase;
`

const NewsArticle = ({ data }) => {
  const newsArticle = data.contentfulNewsArticle
  const title = newsArticle.title
  const date = newsArticle.date
  const slug = newsArticle.slug
  const body = newsArticle.body ? newsArticle.body.childMarkdownRemark.html : ""

  const imageUrl =
    newsArticle.newsPageMetaContent && newsArticle.newsPageMetaContent.metaImage
      ? "https:" + newsArticle.newsPageMetaContent.metaImage.file.url
      : undefined

  const twitterImageUrl =
    newsArticle.newsPageMetaContent &&
    newsArticle.newsPageMetaContent.metaImageTwitter
      ? "https" + newsArticle.newsPageMetaContent.metaImageTwitter.file.url
      : undefined

  return (
    <Layout headerTheme="dark">
      <SEO
        title={`Columbia Artists - ${title}`}
        path={`/news/${slug}`}
        description={
          newsArticle.newsPageMetaContent
            ? newsArticle.newsPageMetaContent.metaDescription.metaDescription
            : undefined
        }
        imageUrl={imageUrl}
        twitterImageUrl={twitterImageUrl}
      />
      <Container>
        <LeftPageContent>
          <Title>{title}</Title>
          <Date>{date}</Date>
        </LeftPageContent>
        <BodyContent
          dangerouslySetInnerHTML={{
            __html: replaceLinksWithNewTabLinks(body),
          }}
        />
      </Container>
    </Layout>
  )
}

export default NewsArticle

export const pageQuery = graphql`
  query GetNews($slug: String!) {
    contentfulNewsArticle(slug: { eq: $slug }) {
      newsPageMetaContent {
        metaDescription {
          metaDescription
        }
        metaImageTwitter {
          file {
            url
          }
        }
        metaImage {
          file {
            url
          }
        }
      }
      slug
      title
      date
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
